@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.lds-spinner {
	position: relative;
}
.lds-spinner div {
	left: 120px;
	top: 10px;
	position: absolute;
	-webkit-animation: lds-spinner linear 1s infinite;
	animation: lds-spinner linear 1s infinite;
	background: #000;
	width: 20px;
	height: 60px;
	border-radius: 20%;
	-webkit-transform-origin: 6px 115px;
	transform-origin: 6px 115px;
}
.lds-spinner.white div {
	background: #fff;
}
.lds-spinner.black div {
	background: #000;
}
.lds-spinner div:nth-child(1) {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-animation-delay: -0.916666666666667s;
	animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg);
	-webkit-animation-delay: -0.833333333333333s;
	animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg);
	-webkit-animation-delay: -0.75s;
	animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-animation-delay: -0.666666666666667s;
	animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg);
	-webkit-animation-delay: -0.583333333333333s;
	animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg);
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-animation-delay: -0.416666666666667s;
	animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg);
	-webkit-animation-delay: -0.333333333333333s;
	animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg);
	-webkit-animation-delay: -0.25s;
	animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
	-webkit-animation-delay: -0.166666666666667s;
	animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg);
	-webkit-animation-delay: -0.083333333333333s;
	animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg);
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.game-loading{
	position: absolute;
	width: 250px;
	height: 250px;
	margin-top: -125px;
	margin-left: -125px;
	top: 50%;
	left: 50%;
	z-index: 3;
}






body{
	font-family: Arial;
	margin: 0;
	padding: 0;
	background: url(//mapgame2.bsrv.su/static/img/bg1.jpg), #050c12;
	background-size: cover;
}
.unselectable{
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}
.container-left,
.container-right{
	padding: 10px;
	width: 275px;
}
.container-left{
	float: left;
}
.container-right{
	float: right;
}
.container-game{
	width: 100%;
	padding-top: 10px;
}
.container-game .block{
	width: 605px;
	height: 605px;
	background: url(//mapgame2.bsrv.su/static/img/bg.png);
	margin: 0 auto;
	padding: 15px;
}
.container-game .footer{
	width: 635px;
	height: 25px;
	clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 5% 100%);
	background: url(//mapgame2.bsrv.su/static/img/bg.png);
	margin: 0 auto;
	padding-top: 5px;
	-webkit-box-shadow: inset 0px 30px 30px -45px rgba(0,0,0,1);
	-moz-box-shadow: inset 0px 30px 30px -45px rgba(0,0,0,1);
	box-shadow: inset 0px 30px 30px -45px rgba(0,0,0,1);
}
.container-game .footer .door{
	width: 100px;
	height: 25px;
	background: #764F2B;
	border-top: 5px solid #593C20;
	border-left: 3px solid #593C20;
	border-right: 3px solid #593C20;
	margin: 0 auto;
	-webkit-border-top-left-radius: 50%;
	-webkit-border-top-right-radius: 50%;
	-moz-border-radius-topleft: 50%;
	-moz-border-radius-topright: 50%;
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
}
.container-game .block .subblock{
	width: 605px;
	height: 605px;
	background: url(//mapgame2.bsrv.su/static/img/bg.png);
}
.container-game .block .subblock .row{
	/*margin: 5px 0;*/
}
.container-game .block .subblock .row:first-child{
	/*margin-top: 0;*/
}
.container-game .block .subblock .row:last-child{
	/*margin-bottom: 0;*/
}
.container-game .block .subblock .row .elem{
	background: url(//mapgame2.bsrv.su/static/img/elem_empty.png) 0 0 no-repeat, #fff;
	width: 113px;
	height: 113px;
	/*display: inline-block;*/
	float: left;
	margin: 4px;
	position: relative;
	-webkit-box-shadow: inset 0px 35px 35px -50px rgba(0,0,0,1);
	-moz-box-shadow: inset 0px 35px 35px -50px rgba(0,0,0,1);
	box-shadow: inset 0px 35px 35px -50px rgba(0,0,0,1);
	cursor: pointer;
}
/*.container-game .block .subblock .row .elem{
	margin: 0 4px;
}
.container-game .block .subblock .row .elem:first-child{
	margin-left: 0;
}
.container-game .block .subblock .row .elem:last-child{
	margin-right: 0;
}*/
.container-game .block .subblock .row .elem:hover::before,
.container-game .block .subblock .row .elem.active{
	background-color: rgba(255, 255, 255, 0.3);
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}
.container-game .block .subblock .row .elem:active::before{
	background-color: rgba(0, 0, 0, 0.2);
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}
.container-game .block .subblock .row .elem.bandits{
	background: url(//mapgame2.bsrv.su/static/img/elem_bandits.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits.goblins1{
	background: url(//mapgame2.bsrv.su/static/img/elem_goblins1.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits.goblins2{
	background: url(//mapgame2.bsrv.su/static/img/elem_goblins2.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits.troll{
	background: url(//mapgame2.bsrv.su/static/img/elem_troll.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits.minotaur{
	background: url(//mapgame2.bsrv.su/static/img/elem_minotaur.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits.nezhit{
	background: url(//mapgame2.bsrv.su/static/img/elem_nezhit.png) 0 0 no-repeat, #f00;
}
.container-game .block .subblock .row .elem.bandits .hp{
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	text-align: center;
	background: #c00;
	padding: 0 5px;
	color: #fff;
}
.container-game .block .subblock .row .elem.health{
	background: url(//mapgame2.bsrv.su/static/img/elem_health.png) 0 0 no-repeat, #0f0;
}
.container-game .block .subblock .row .elem.damage{
	background: url(//mapgame2.bsrv.su/static/img/elem_damage.png) 0 0 no-repeat, #00f;
}
.container-game .block .subblock .row .elem.evasion{
	background: url(//mapgame2.bsrv.su/static/img/elem_evasion.png) 0 0 no-repeat, #0ff;
}
.container-game .block .subblock .row .elem.nextlevel{
	background: url(//mapgame2.bsrv.su/static/img/elem_nextlevel.png) 0 0 no-repeat, #ff0;
}
.container-game .block .subblock .row .elem.fog{
	background: url(//mapgame2.bsrv.su/static/img/elem_fog.png) 0 0 no-repeat, #f5f5f5;
}
.container-game .block .subblock .row .elem.fog-available{
	background: url(//mapgame2.bsrv.su/static/img/elem_fog_available.png) 0 0 no-repeat, #f5f5f5;
}
.container-game .block .subblock .row .elem.fog-blocked{
	background: url(//mapgame2.bsrv.su/static/img/elem_fog_blocked.png) 0 0 no-repeat, #f5f5f5;
}
.game-log{
	margin-top: 240px;
	width: 100%;
}
.game-log .panel{
	margin: 0 10px;
}
.game-log .panel .panel-heading{
	cursor: pointer;
}